import React from 'react';
import './button.scss';
import { Link } from 'gatsby';
import classNames from 'classnames';
import arrowRightIcon from '../../images/icons/arrow-right.svg';

export const Button = ({ link, primary, withIcon, children, fullWidth, submitBtn, loading }) => {
  return (
    <>
      {!submitBtn &&
        <Link
          to={link}
          aria-label={children}
          className={classNames("btn", {
            "btn--primary": primary,
            "btn--with_icon": withIcon,
            "btn--full_width": fullWidth,
          })}>
          <span className="btn__text">{children}</span>
          {withIcon &&
            <img src={arrowRightIcon} alt="icon" className="btn__icon"/>
          }
        </Link>
      }
      {submitBtn &&
        <button
          aria-label={children}
          className={classNames("btn btn--submit", {
            "btn--primary": primary,
            "btn--loading": loading,
            "btn--with_icon": withIcon,
            "btn--full_width": fullWidth,
          })}>
          <span className="btn__text">{children}</span>
          {withIcon &&
            <img src={arrowRightIcon} alt="icon" className="btn__icon"/>

          }
        </button>
      }

    </>
  );
};
