import React, { Fragment } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout/layout";
import { NotFoundSection } from "../components/NotFoundSection/NotFoundSection";
import { graphql} from "gatsby";


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

// markup
const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <Layout
      pageTitle="Whoops! Something went wrong!"
      description="The page you are looking for may have been removed or it’s name
      changed.Make sure you entered the site name correctly in the address field of your web browser."
    >
      <NotFoundSection
        title={
          <Fragment><span>Oooops!</span> {t("not-found.title")} </Fragment>
        }
        description={
          <Fragment>
            <p>{t("not-found.text1")}</p>
            <p>{t("not-found.text2")}</p>
          </Fragment>
        }
        image="404.png"
        btnText={t("global.back-to-home")}
      />
    </Layout>
  );
};

export default NotFoundPage;
