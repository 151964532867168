import React from "react";
import "./not-found-section.scss";
import FadeDown from "../FadeDown/FadeDown";
import { Button } from '../Button/Button';
import ImageCustom from "../ImageCustom/ImageCustom";

export const NotFoundSection = ({ title, description, image, btnText }) => {
  return (
    <section className="not-found-section">
      <div className="container">
        <div className="gr-12">
          <div className="not-found-section__wrap">
            <FadeDown>
              <ImageCustom
                src={image}
                alt={title}
                className="rspimg image"
              ></ImageCustom>
            </FadeDown>
            <FadeDown>
              <p className="title">{title}</p>
            </FadeDown>
            <FadeDown>
              <p className="description">{description}</p>
            </FadeDown>
            <FadeDown>
              <Button link="/" primary withIcon>
                {btnText}
              </Button>
            </FadeDown>
          </div>
        </div>
      </div>
    </section>
  );
};
